import React from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'

// Components
import Thumbnail from './Thumbnail.js'
import Reviews from './reviews.js'
import Houses from './Houses.js'
// CSS
import '../styles/cards.css'
import '../styles/grid.css'
import '../styles/users.css'
import '../styles/utube.css'
import '../styles/nav.css'
import '../styles/gallery.css'
import '../styles/review.css'
import logo from '../ga.jpg'

class House extends React.Component {
	state = {
		amAr : [],
		house: {
			title:"",
			uId: "",
			images: [],
			type: {
				name: ''
			},
			host: {
				name: '',
				avatar: ''
			},
			amenities: [],
			rating: 0
		},
		reviews: []
	}
	constructor(props) {
		super(props)
		this.star = this.star.bind(this)
		this.guests = this.guests.bind(this)
}
	guests(){
		let op = []
		for(let i = 1; i <= this.state.house.guests ;i++){
			op.push(<option value = {i}> guests {i} </option> )
		}
		return(op)
	}

	componentWillMount() {
		console.log(this.props);
		axios
			//.get(`${process.env.REACT_APP_API}/houses/5e4cd7195cb7b24bb0fd0bec`)
			.get(`${process.env.REACT_APP_API}/houses/${this.props.match.params.id}`)
			.then((res,req) => {
				let ama = []
				this.setState({
					house: res.data
				})
				this.state.house.amenities.map(am=>(
					ama.push(am.name)
				))
				this.setState({
					amAr : ama
				})
				console.log(this.state.amAr);
			})

	}

	star(){
		axios.patch(`${process.env.REACT_APP_API}/houses/${this.props.match.params.id}`).then(resp=> {
			console.log(resp.data)
		})
	}
componentDidMount(){
	console.log(this.state.house);
}
	render() {
		return (
			<>
				<div class="videoWrapper">
				<br />
				<br />
				<nav>
					<Link to="/">
					<img src={logo} height='50'></img>
					</Link>
				</nav>
				<iframe
				 width="560"
				 height="315"
				 src={`https://www.youtube.com/embed/${this.state.house.uId}?autoplay=1`}
				 frameborder="0"
				 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
				 allowfullscreen="allowfullscreen"
		     mozallowfullscreen="mozallowfullscreen"
		     msallowfullscreen="msallowfullscreen"
		     oallowfullscreen="oallowfullscreen"
		     webkitallowfullscreen="webkitallowfullscreen">
				 </iframe>
				 </div>
			 </>
		)
	}
}

export default House

import React, {useEffect} from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Houses from './Houses'
import House from './House'
import Plus from './Plus'
import Mainpage from './Mainpage'
import AddStories from './AddStories'
import Trial from './trial'
import Tag from './Tag'
import ReactGa from 'react-ga'

function App(){
	useEffect(()=>{
		ReactGa.initialize('UA-179020597-1')
		ReactGa.pageview(window.location.pathname)
	},[])

		return (
			<BrowserRouter>
				<Switch>
					<Route path='/houses/:id' component={House} />
					<Route path ='/add' component ={Houses} />
					<Route path='/trial' component ={Trial} />
					<Route path='/:tag' component ={Tag} />
					<Route path="/" component={Mainpage} />
				</Switch>
			</BrowserRouter>
		)

}

export default App

import React from 'react';
import axios from 'axios'
import Houses from './Houses.js'
import { Link } from 'react-router-dom'
import Thumbnail from './Thumbnail'
import GoogleMap from 'google-map-react'
import '../styles/cards.css'
import '../styles/grid.css'
import '../styles/maps.css'
import '../styles/nav.css'
import '../styles/grid.css'
import lfs from '../lfs.png'
import logo from '../ga.jpg'

class Tag extends React.Component{
  constructor(props){
    super(props)
  }
  state={
    houses:[]
  }
  componentWillMount(){
    console.log(this.props.location.pathname);
    axios
      .get(`${process.env.REACT_APP_API}/amenities/${this.props.location.pathname}`)
        .then(res=>{
          console.log(res.data);
          this.setState({
            houses:res.data
          })
        })
      }
  render(){
    return(
      <>
      <div id ='body'>
      <nav>
        <Link to="/">
        <img src={logo} height='80'></img>
        </Link>
      </nav>
      <div className="grid map">
        <div className="grid four large thumb">
          {// List of thumbnails
          this.state.houses.map((house,index) => (
            <Thumbnail house_amenities={house.amenities} house_uId = {house.uId} house_id = {house._id} house_bedrooms ={house.bedrooms} house_title ={house.title} />
          ))}
        </div>
        <div className="map madeBy">
        <img src={lfs} id ='lfs'></img>
        <p><b>Curated by Mother-Daughter pair who want people to enjoy and learn from stories : Rachana and Sonika Agarwal</b></p>
        </div>
      </div>
      </div>
      </>
    )
  }
}

export default Tag;

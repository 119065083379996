import React from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom'
import GoogleMap from 'google-map-react'
import '../styles/cards.css'
import '../styles/grid.css'
import '../styles/maps.css'
import '../styles/nav.css'
import Thumbnail from './Thumbnail.js'
import { withRouter } from 'react-router-dom'

class AddStories extends React.Component {
	constructor(props) {
		super(props)
		this.amenities = this.amenities.bind(this)

}
	state = {

	}

	render() {
		return (
			<>
      <p> hi </p>
			</>
		)

	}

}

export default AddStories

import React from 'react';
import axios from 'axios'
import '../styles/cards.css'
import '../styles/grid.css'
import '../styles/maps.css'
import '../styles/nav.css'
import '../styles/grid.css'
class AddStories extends React.Component{
  constructor(props) {
    super(props)
    this.updateTitle = this.updateTitle.bind(this);
    this.updateUid = this.updateUid.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.setAm1 = this.setAm1.bind(this);
    this.setAm2 = this.setAm2.bind(this);
    this.setAm3 = this.setAm3.bind(this);
		this.view = this.view.bind(this);
		this.amenities = this.amenities.bind(this);

  }
  state={
    title:'',
    uId: '',
    lang:'',
    am1:'',
    am2:'',
    am3:''
  }
  updateTitle(e){
    this.setState({
      title : e.target.value
    })
  }
  updateUid(ev){
    this.setState({
      uId: ev.target.value
    })
  }
  setLanguage(e){
    this.setState({
      lang:e.target.value
    })
  }
  setAm1(e){
    this.setState({
      am1:e.target.value
    })
  }
  setAm2(e){
    this.setState({
      am2:e.target.value
    })
  }
  setAm3(e){
    this.setState({
      am3:e.target.value
    })
  }
  view(){
    axios
			.post(`${process.env.REACT_APP_API}/houses/${this.state.title}/${this.state.uId}/${this.state.lang}/${this.state.am1}/${this.state.am2}/${this.state.am3}`)
				.then(res=>{
					console.log(res.data);
				})
				.catch(err=>{
					console.log(err);
				})

    console.log(this.state);
  }
	amenities(){
		let flag=0
		axios
			.get(`http://localhost:4000/amenities`)
				.then(res=>{
					for (let i=0;i<res.data.length;i++){
						if(this.state.am1.toLowerCase()==res.data[i].name.toLowerCase()){
							this.setState({
								am1: res.data[i]._id
							})
							console.log(this.state.am1);
							flag+=1
						}
						else{
							console.log('unequal');

						}
					}
					if (flag!==1){
						let d= document.getElementById('info')
						d.innerHTML='wrong am1'
					}
					else{
						let d= document.getElementById('info')
						d.innerHTML=''
					}


					//am2
					for (let i=0;i<res.data.length;i++){
						if(this.state.am2.toLowerCase()==res.data[i].name.toLowerCase()){
							this.setState({
								am2: res.data[i]._id
							})
							console.log(this.state.am1);
							flag+=1
						}
						else{
							console.log('unequal');

						}
					}
					if (flag!==1){
						let d= document.getElementById('info')
						d.innerHTML='wrong am2'
					}
					else{
						let d= document.getElementById('info')
						d.innerHTML=''
					}


					//am3

					for (let i=0;i<res.data.length;i++){
						if(this.state.am3.toLowerCase()==res.data[i].name.toLowerCase()){
							this.setState({
								am3: res.data[i]._id
							})
							console.log(this.state.am1);
							flag+=1
						}
						else{
							console.log('unequal');

						}
					}
					if (flag!==1){
						let d= document.getElementById('info')
						d.innerHTML='wrong am3'
					}
					else{
						let d= document.getElementById('info')
						d.innerHTML=''
					}
				})
				.catch(err=>{
					console.log(err);
				})
	}

  render(){
    return(
      <>
      //title
      <input id ='name' type="text" placeholder="TITLE" onChange = {this.updateTitle}/>
      <br />
      <br />
      //UID
      <input id ='uId' type="text" placeholder="UID" onChange = {this.updateUid}/>
      <br />
      <br />
      //language
      <select onChange = {this.setLanguage}>
        <option value=""> </option>
        <option value='English'> English </option>
        <option value='Hindi'> Hindi </option>
      </select>
      <br />
      <br />
      //amenities
      <input type="text" onChange ={this.setAm1} id ='am1' placeholder="AM 1" />
      <input type="text" onChange ={this.setAm2} id ='am2' placeholder="AM 2" />
      <input type="text" onChange ={this.setAm3} id ='am3' placeholder="AM 3" />

			<button onClick={this.view}> post </button>
			<button onClick={this.amenities}> ame </button>
			<p id ='info'></p>
      </>
    )
  }
}

export default AddStories

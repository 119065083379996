import React from 'react';
import axios from 'axios'

class Trial extends React.Component{
  constructor(props) {
    super(props)
    this.updateTitle = this.updateTitle.bind(this);
    this.updateUid = this.updateUid.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.setAm1 = this.setAm1.bind(this);
    this.setAm2 = this.setAm2.bind(this);
    this.setAm3 = this.setAm3.bind(this);
    this.setAm4 = this.setAm4.bind(this);
    this.setAm5 = this.setAm5.bind(this);
    this.setAm6 = this.setAm6.bind(this);
    this.setAm7 = this.setAm7.bind(this);
    this.setNuma = this.setNuma.bind(this);
    this.view = this.view.bind(this);
    this.createAm = this.createAm.bind(this);
    this.chetag = this.chetag.bind(this);
    this.updateHtitle = this.updateHtitle.bind(this);
    this.setchange = this.setchange.bind(this);
  }
  state={
    title:'',
    uId: '',
    lang:'',
    am1:'',
    am2:'',
    am3:'',
    am4:'',
    am5:'',
    am6:'',
    am7:'',
    amC:'',
    hTitle:'',
    tags:null,
    numa:null
  }

  componentWillMount(e){
    document.title='ADD STORIES'
    axios
      .get(`http://localhost:4000/amenities`)
        .then(res=>{
          this.setState({
            tags: res.data
          })
          console.log(res.data);
        })
        .catch(err=>{
          console.log(err);
        })
  }
  updateHtitle(e){
    this.setState({
      hTitle:e.target.value
    })
  }
  updateTitle(e){
    this.setState({
      title : e.target.value
    })
  }
  updateUid(ev){
    this.setState({
      uId: ev.target.value
    })
  }
  setLanguage(e){
    this.setState({
      lang:e.target.value
    })
  }
  setAm1(e){
    if(e.target.value!=''){
      let rvalue=e.target.value.toLowerCase()
      let k= 0;
      let seta= ''
      for(let i=0;i<this.state.tags.length;i++){
        if (this.state.tags[i].name.toLowerCase() == rvalue){
          //console.log(this.state.tags[i]._id);
          k+=1
          seta=this.state.tags[i]._id
        }
      }
      if (k!==0){
        this.setState({
          am1: seta
        })
      }

    }
    else if (e.target.value == ''){
      this.setState({
        am1:''
      })
    }

  }
  setAm2(e){
    if(e.target.value!=''){
      let rvalue=e.target.value.toLowerCase()
      let k= 0;
      let seta= ''
      for(let i=0;i<this.state.tags.length;i++){
        if (this.state.tags[i].name.toLowerCase() == rvalue){
          //console.log(this.state.tags[i]._id);
          k+=1
          seta=this.state.tags[i]._id
        }
      }
      if (k!==0){
        this.setState({
          am2: seta
        })
      }

    }
    else if (e.target.value == ''){
      this.setState({
        am2:''
      })
    }

  }
  setAm3(e){
    if(e.target.value!=''){
      let rvalue=e.target.value.toLowerCase()
      let k= 0;
      let seta= ''
      for(let i=0;i<this.state.tags.length;i++){
        if (this.state.tags[i].name.toLowerCase() == rvalue){
          //console.log(this.state.tags[i]._id);
          k+=1
          seta=this.state.tags[i]._id
        }
      }
      if (k!==0){
        this.setState({
          am3: seta
        })
      }

    }
    else if (e.target.value == ''){
      this.setState({
        am3:''
      })
    }

  }
  setAm4(e){
    if(e.target.value!=''){
      let rvalue=e.target.value.toLowerCase()
      let k= 0;
      let seta= ''
      for(let i=0;i<this.state.tags.length;i++){
        if (this.state.tags[i].name.toLowerCase() == rvalue){
          //console.log(this.state.tags[i]._id);
          k+=1
          seta=this.state.tags[i]._id
        }
      }
      if (k!==0){
        this.setState({
          am4: seta
        })
      }

    }
    else if (e.target.value == ''){
      this.setState({
        am4:''
      })
    }

  }
  setAm5(e){
    if(e.target.value!=''){
      let rvalue=e.target.value.toLowerCase()
      let k= 0;
      let seta= ''
      for(let i=0;i<this.state.tags.length;i++){
        if (this.state.tags[i].name.toLowerCase() == rvalue){
          //console.log(this.state.tags[i]._id);
          k+=1
          seta=this.state.tags[i]._id
        }
      }
      if (k!==0){
        this.setState({
          am5: seta
        })
      }

    }
    else if (e.target.value == ''){
      this.setState({
        am5:''
      })
    }

  }
  setAm6(e){
    if(e.target.value!=''){
      let rvalue=e.target.value.toLowerCase()
      let k= 0;
      let seta= ''
      for(let i=0;i<this.state.tags.length;i++){
        if (this.state.tags[i].name.toLowerCase() == rvalue){
          //console.log(this.state.tags[i]._id);
          k+=1
          seta=this.state.tags[i]._id
        }
      }
      if (k!==0){
        this.setState({
          am6: seta
        })
      }

    }
    else if (e.target.value == ''){
      this.setState({
        am6:''
      })
    }

  }
  setAm7(e){
    if(e.target.value!=''){
      let rvalue=e.target.value.toLowerCase()
      let k= 0;
      let seta= ''
      for(let i=0;i<this.state.tags.length;i++){
        if (this.state.tags[i].name.toLowerCase() == rvalue){
          //console.log(this.state.tags[i]._id);
          k+=1
          seta=this.state.tags[i]._id
        }
      }
      if (k!==0){
        this.setState({
          am7: seta
        })
      }

    }
    else if (e.target.value == ''){
      this.setState({
        am7:''
      })
    }

  }
  setNuma(e){
    this.setState({
      numa: e.target.value
    })
  }
  setchange(e){
    this.setState({
      amC: e.target.value
    })
  }

  chetag(e){
    let d=document.getElementById('ames')
    let amall=[
    this.state.am1,
    this.state.am2,
    this.state.am3,
    this.state.am4,
    this.state.am5,
    this.state.am6,
    this.state.am7
    ]
    let amTot=[]
    for(let j=0; j<7;j++){
      if (amall[j] !== ''){
        let p=' '+amall[j]+' '
        console.log(p);
        amTot.push(p)
      }
    }
    d.innerHTML=amTot

    if (amTot.length != this.state.numa){
      console.log(amTot.length);
      console.log(this.state.numa);
      let op=document.getElementById('helptags')
      let l=amTot.length
      let l1=this.state.numa
      let lo='Call Sonika'
      op.innerHTML=lo
    }
    else{
      let op=document.getElementById('helptags')
      op.innerHTML=''

    }
  }

  view(){
    if (this.state.numa==2){
      axios
        .post(`http://localhost:4000/houses?title=${this.state.title}&hTitle=${this.state.hTitle}&uId=${this.state.uId}&lang=${this.state.lang}&am1=${this.state.am1}&am2=${this.state.am2}`)
          .then(res=>{
            console.log(res.data);
          })
          .catch(err=>{
            console.log(err);
          })
    }

    else if (this.state.numa==3){
      axios
        .post(`http://localhost:4000/houses?title=${this.state.title}&hTitle=${this.state.hTitle}&uId=${this.state.uId}&lang=${this.state.lang}&am1=${this.state.am1}&am2=${this.state.am2}&am3=${this.state.am3}`)
          .then(res=>{
            console.log(res.data);
          })
          .catch(err=>{
            console.log(err);
          })
    }

    else if (this.state.numa==4){
      axios
        .post(`http://localhost:4000/houses?title=${this.state.title}&hTitle=${this.state.hTitle}&uId=${this.state.uId}&lang=${this.state.lang}&am1=${this.state.am1}&am2=${this.state.am2}&am3=${this.state.am3}&am4=${this.state.am4}`)
          .then(res=>{
            console.log(res.data);
          })
          .catch(err=>{
            console.log(err);
          })
    }

    else if (this.state.numa==5){
      axios
        .post(`http://localhost:4000/houses?title=${this.state.title}&hTitle=${this.state.hTitle}&uId=${this.state.uId}&lang=${this.state.lang}&am1=${this.state.am1}&am2=${this.state.am2}&am3=${this.state.am3}&am4=${this.state.am4}&am5=${this.state.am5}`)
          .then(res=>{
            console.log(res.data);
          })
          .catch(err=>{
            console.log(err);
          })
    }

    else if (this.state.numa==6){
      axios
        .post(`http://localhost:4000/houses?title=${this.state.title}&hTitle=${this.state.hTitle}&uId=${this.state.uId}&lang=${this.state.lang}&am1=${this.state.am1}&am2=${this.state.am2}&am3=${this.state.am3}&am4=${this.state.am4}&am5=${this.state.am5}&am6=${this.state.am6}`)
          .then(res=>{
            console.log(res.data);
          })
          .catch(err=>{
            console.log(err);
          })
    }
    else if (this.state.numa==7){
      axios
        .post(`http://localhost:4000/houses?title=${this.state.title}&hTitle=${this.state.hTitle}&uId=${this.state.uId}&lang=${this.state.lang}&am1=${this.state.am1}&am2=${this.state.am2}&am3=${this.state.am3}&am4=${this.state.am4}&am5=${this.state.am5}&am6=${this.state.am6}&am7=${this.state.am7}`)
          .then(res=>{
            console.log(res.data);
          })
          .catch(err=>{
            console.log(err);
          })
    }

    else {
      let p=document.getElementById('help')
      p.innerHTML='Call Sonika'
    }
  }

createAm(e){
  axios
    .post(`http://localhost:4000/amenities/${this.state.amC}`)
      .then(res=>{
        console.log(res.data);
        this.setState({
          tags:res.data
        })
      })
      .catch(err=>{
        console.log(err);
      })
}
  render(){
    return(
      <>

      //title
      <input id ='name' type="text" placeholder="TITLE" onChange = {this.updateTitle}/>
      <br />
      <br />
      //htitle
      <input id ='name' type="text" placeholder="HINDI TITLE" onChange = {this.updateHtitle}/>
      <br />
      <br />
      //UID
      <input id ='uId' type="text" placeholder="UID" onChange = {this.updateUid}/>
      <br />
      <br />
      //language
      <select onChange = {this.setLanguage}>
        <option value=""> </option>
        <option value='English'> English </option>
        <option value='Hindi'> Hindi </option>
      </select>
      <br />
      <br />
      //amenities
      <br />
      <input type="text" onChange ={this.setAm1} id ='am1' placeholder="AM 1" />
      <br />
      <input type="text" onChange ={this.setAm2} id ='am2' placeholder="AM 2" />
      <br />
      <input type="text" onChange ={this.setAm3} id ='am3' placeholder="AM 3" />
      <br />
      <input type="text" onChange ={this.setAm4} id ='am4' placeholder="AM 4" />
      <br />
      <input type="text" onChange ={this.setAm5} id ='am5' placeholder="AM 5" />
      <br />
      <input type="text" onChange ={this.setAm6} id ='am6' placeholder="AM 6" />
      <br />
      <input type="text" onChange ={this.setAm7} id ='am7' placeholder="AM 7" />
      <br />
      <p> Enter the number of amenities </p>
      <input type="number" onChange ={this.setNuma} id ='num' placeholder="NUM" />
      <p id='help'> </p>
      <button onClick={this.view}> post </button>
      <br/>
      <button onClick={this.chetag}> Check Tags </button>
      <br/>
      <p id= 'helptags'> </p>
      <br/>
      <p id='ames'> </p>
      <br />
      <br />
      <br />
      <br />
      <p> ADD TAG</p>
      <input type="text" onChange ={this.setchange} id ='tag' placeholder="TAG" />
      <button onClick={this.createAm}> post </button>

      </>
    )
  }
}

export default Trial

import React, { Component } from 'react';
import Houses from './Houses.js'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Thumbnail from './Thumbnail'
import GoogleMap from 'google-map-react'
import '../styles/cards.css'
import '../styles/grid.css'
import '../styles/maps.css'
import '../styles/nav.css'
import '../styles/grid.css'
import lfs from '../lfs.png'
import logo from '../ga.jpg'
/*
import '../styles/cards.css'
import '../styles/maps.css'
import '../styles/nav.css'*/
class Mainpage extends React.Component {
  constructor(props) {
		super(props)
    this.filter= this.filter.bind(this)
    this.all= this.all.bind(this)
    this.u = this.u.bind(this)
    this.keyup = this.keyup.bind(this)
    this.typesFilter = this.typesFilter.bind(this)
    this.filterBr = this.filterBr.bind(this)
    this.amenities = this.amenities.bind(this)
    this.AmenitiesButtons =this.AmenitiesButtons.bind(this)
    this.addStory =this.addStory.bind(this)
  }
  state ={
    houses: [],
    genre:'',
    bedrooms: 1,
    amen :"All",
    amObj: [],
    type:'All Types',
    amenities:[],
    value : 'cc',
    rating:0,
    types: [],
    map: {
      key: {
        key: 'AIzaSyBKMVj4gaJLU9GTV1zOaWQj7ggKVbXQep0'
      },
      center: {
        lat: -8.652,
        lng: 115.137
      },
      zoom: 14
    }
  }
  addStory(){
    console.log('added');
  }
  u(){
    console.log('u');
		axios
			.get(`${process.env.REACT_APP_API}/houses`)
			.then(res => {
				this.setState({
					houses : res.data
				})
			})
	}
	filterBr(e){
		this.u()
		let bdf = []
		axios
			.get(`${process.env.REACT_APP_API}/houses`)
			.then(res => {
				this.state.houses.map(house=>{
					let ameAr=[]
					let a =""
					house.amenities.map(amy => {
						ameAr.push(amy.name)
					})
					if(this.state.amen !== "All" ){
  					console.log(this.state.amen);
  					if(ameAr.includes(this.state.amen)){
  						bdf.push(house)
  					}
				}
        else{
          bdf = res.data
        }
				})
				this.setState({
					houses: bdf
				})
			})
			.catch( err => {
					console.log(err);
			})

	}
typesFilter(event){
	this.setState({
		amen: event.target.value
	})
	console.log(this.state.amen);
	console.log(event.target.value);
this.filterBr()
}
amenities(){
	let amenityArray =[]
	for(let i =0; i< this.state.amenities.length; i++){
		let amenity = this.state.amenities[i]
		let op = <option> {amenity} </option>
		amenityArray.push(op)
	}
	return(amenityArray)
}

	keyup(event){
		let arr = []
		let inpu = document.getElementById('inp').value
		axios
			.get(`${process.env.REACT_APP_API}/houses`)
			.then(res => {
				res.data.map(house=>{
					let title = house.title.toLowerCase()
					if(title.includes(inpu)  ){
						arr.push(house)
					}
				})
				this.setState({
					houses:arr
				})
			})
			.catch(err=>{
				console.log('error');
			})

			event.preventDefault();

	}
	componentWillMount() {
		axios
			.get(`${process.env.REACT_APP_API}/houses`)
			.then(res => {
				let p=0 ;
				this.setState({
					houses: res.data,
				})
			})
			.catch(err => {
				console.log({ err })
			})
	}

	componentDidMount(){
		axios
		.get(`${process.env.REACT_APP_API}/amenities`)
		.then(res=>{
			let amenitiesArray =[]
			let ame =[]
			res.data.map(am=>{
				ame.push(am._id);
				amenitiesArray.push(am.name)
			})
			this.setState({
				amenities: amenitiesArray
			})
		})
	}
filter(ev){
  let gen = document.querySelector('.sel').classList.remove('sel')
  ev.target.classList.add('sel')
  this.setState({
    genre: ev.target.value
  })
  let g =this.state.genre
  //let gens = document.getElementById(ev.target.value).classList.add('sel')
  let a =ev.target.value
  axios
    .get(`${process.env.REACT_APP_API}/houses`)
    .then(res=>{
      let houseAm=[]
      res.data.map(house=>{
        let amHo =[]
        house.amenities.map(amy=>{
          amHo.push(amy.name)
        })
        if(amHo.includes(a)){
          houseAm.push(house)
        }
      })
      this.setState({
        houses:houseAm
      })
      console.log('this.state.houses.length');
      console.log(this.state.houses.length);
    })
}
AmenitiesButtons(){
  let amenitiesArray =[]
  this.state.amenities.sort()
  for(let i =0; i< this.state.amenities.length; i++){
    let amenity = this.state.amenities[i]
    let op =<button className = 'typesButtons' id = {amenity} value ={amenity} onClick={this.filter}>{amenity}</button>
    amenitiesArray.push(op)
  }
  return(amenitiesArray)
}
all(){
  let gen = document.querySelector('.sel').classList.remove('sel')
  let ev = document.getElementById('All').classList.add('sel')
  axios
    .get(`${process.env.REACT_APP_API}/houses`)
    .then(res=>{
      this.setState({
        houses:res.data
      })
    })
}
componentWillMount(){
  axios
    .get(`${process.env.REACT_APP_API}/houses`)
    .then(res=>{
      console.log(res.data.length);
      this.setState({
        houses:res.data
      })
    })
}
  render() {
    return (
      <>
    <div id ='body'>
    <nav>
      <Link to="/">
      <img src={logo} height='80'></img>
      </Link>
    </nav>
    {/*}<nav>
      <Link to="/add">
      <button>add</button>
      </Link>
    </nav>*/}
      <button className ='typesButtons sel' id ='All' value = 'All' onClick ={this.all}>All</button>
      {this.AmenitiesButtons()}
      <br/>

        <div className="filters" id = 'thisone'>
          <select onChange ={this.typesFilter}>
            <option value="All">All</option>
            {this.amenities()}
          </select>
          <select>
            <option value="Hindi">Hindi</option>
          </select>
          <form onSubmit = {this.keyup}>
            <input type="text" id ='inp' className="search" placeholder="Search..." />
          </form>
        </div>
        <div className="grid map">

          <div className="grid four large thumb">
            {// List of thumbnails
            this.state.houses.map((house,index) => (
              <Thumbnail house_amenities={house.amenities} house_uId = {house.uId} house_id = {house._id} house_bedrooms ={house.bedrooms} house_title ={house.title} />
            ))}
          </div>
          <div className="map madeBy">
          <img src={lfs} id ='lfs'></img>
          <p><b>Curated by Mother-Daughter pair who want people to enjoy and learn from stories : Rachana and Sonika Agarwal</b></p>
          </div>
        </div>
    </div>
      </>
    )
  }

}
export default Mainpage

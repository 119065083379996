import React, { Component } from 'react';
import Houses from './Houses.js'
import { Link } from 'react-router-dom'
import axios from 'axios'
/*
house_image
id
typename
Bedrooms
title
city
region
price
rating
*/
class Thumbnail extends React.Component {
  constructor(props) {
		super(props)
    this.star = this.star.bind(this)
    this.amenities=this.amenities.bind(this)
  }
  state={
    ame:[]
  }
amenities(){
  let ama=[]
  this.props.house_amenities.map(am=>{
    ama.push(am.name)
  })
  /*axios
    .get(`${process.env.REACT_APP_API}/amenities`)
      .then(res=>{
        this.setState({
          ame:res.data
        })
      })
  let ama=[]
  let cname=''
  this.props.house_amenities.map(am=>{
    this.state.ame.map(ch=>{
      if(ch._id==am){
        cname=ch.name;
      }
    })
    ama.push(cname)
  })*/
  if(ama.length!==1){
    return("• "+ama[0]+" • "+ama[1])
  }
  else{
    console.log();
    return ( "• " + ama )
  }

}

 star (rate){
   let stars=[]
   for (let i =0; i < rate ;i++){
     stars.push(<i className ='fas fa-star fa-xs'></i>)
   }
   for (let i =0; i < 5-(rate) ;i++){
     stars.push(<i className ='far fa-star fa-xs'></i>)
   }
   return(stars)
 }
 deletee(even){
     console.log(even);
 }
  render() {

    return (
      <>
      <Link
        className="card link"
        to={`/houses/${this.props.house_id}`}
        key={this.props.house_id}
      >
        <div className="image">
          <img id ='img' src = {`https://img.youtube.com/vi/${this.props.house_uId}/0.jpg`}  />
        </div>
        <div className="content">
          <small className="meta">
          </small>
          <h2>{this.props.house_title}</h2>
          {this.amenities()}
        </div>

      </Link>

      </>
    )
  }

}
export default Thumbnail
